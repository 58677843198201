import React, { useEffect, useState } from "react"
// Components
import Layout from "../components/layout/Layout"
import Header from "../components/layout/Header"

import HeaderImage from "../components/general/HeaderImage"
import GroupPic from "../images/announcement/GroupPicture.png"
import OfficeImageA from "../images/announcement/announcement-image.jpeg"
import OfficeImageB from "../images/announcement/Office.jpg"



// Images

// For Netlify's deployment errors
const activeWindow = typeof window !== "undefined" ? window : ''

const AnnouncmentPage = ({ location }) => {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (activeWindow) {
            setIsMobile(window.innerWidth < 768)
        }
    }, [])

    const handleResize = () => setIsMobile(window.innerWidth < 768)

    useEffect(() => {
        if (activeWindow) {
            handleResize()
            window.addEventListener("resize", handleResize)

            return () => window.removeEventListener("resize", handleResize)
        }
    }, [])

    const firstParagraph = () => {
        return (
            <>
             <p className="leading-relaxed">
                    We’re acquiring the amazing <a href="https://bit.ly/3JEBMqK" target="_blank" className="underline">HEDGE Real Estate</a> to create an unstoppable force - the industry’s most relevant tenant and asset owner advisory firm. </p>
                    <br/>
                    <p className="leading-relaxed">
                    Kontor will now be able to offer a comprehensive range of services across the full spectrum of space, helping the market deliver exceptional offices that meet the needs and expectations of our clients in this day and age. We can’t wait to welcome the HEDGE team into our own (and meet all the office dogs, of course).            
                    </p> 
                    <br/>
                    <p className="leading-relaxed">
                    We’ve come a long way since 2014. After starting out with a clear focus on helping start-ups and scale-ups find the perfect space to grow their business, we’ve grown quickly to become a leading tenant advisory firm handling flexible, managed and leased properties, and many of our amazing clients have evolved into unicorns and household names (Monzo, Deliveroo, Binance, Braze, Revolut to name a few).
                    </p> 
                    <br/>
                    <p className="leading-relaxed">
                    Since the pandemic, it’s evident the market is consuming space differently, and flexibility, agility and a desire for inspirational spaces have increased; trends that have always been at the heart of what we do. Asset owners are offering flexible and managed spaces, but need help on how to position them... enter us.                    </p>               
               </>
        );
    }

    const secondParagraph = () => {
        return (
            <>
                <p>
                By acquiring HEDGE we’re able to give asset owners the insights they need to succeed.                         </p>
                    <br/>
                <p>
                The team - led by founders Andrew Gibson, Paul Gold and Ben Orchard-Smith - collectively have 60 years’ experience in real estate. HEDGE also works with London’s largest asset owners, including The Crown Estate, The Pollen Estate, GPE, Derwent London, The Office Group and British Land. Pair all this with the experience and knowledge of the Kontor team, and we’re onto a great thing.                        </p>
                    <br/>
                <p>
                HEDGE’s mission when set up three years ago was to advise asset owners on how to create space that works now and in the future, across all types of offices. Integrating with Kontor is a key milestone in our ambition to build an office advisory firm with unrivalled understanding of what occupiers and asset owners are seeking.                        </p>
            </>
        );
    }

    const thirdParagraph = () => {
        return (
            <>
            <p>Our own James Townsend puts it like this...</p>
            <br/>
            <p>We saw an opportunity for growth. For years we’ve turned down opportunities to represent asset owners so we could focus on giving the best possible service to occupiers. Through this acquisition, we can now offer asset owners the transformational solutions they’re after. As Kontor grows, we continue to be acquisitive and keen to work with other best in class operators who are interested in joining our growth journey...</p> 
         </>
        );
    }

    const fourthParagraph = () => {
        return (
            <>
            <p> 
            And Andrew Gibson, co-founder of HEDGE says... </p>
            <br/>
            <p>The acquisition is the logical coming together of two entrepreneurial companies, which collectively have a more powerful offering for both asset owners and occupiers. We have the ambition, drive and knowledge to help the market deliver spaces people really need. The whole HEDGE team is excited to use this new platform to help the most forward-thinking asset owners deliver best-in-class workspaces...                            
            </p>
            </>
        );
    }


    return (
        <Layout container={false} activePage="/announcement" location={location}>
            <Header title="Announcement" />

            {/* Landing Section  */}
            <HeaderImage
                title={<><span className="font-bold">Kontor</span> acquires <span className="font-bold">HEDGE</span> to create a complete office advisory business</>}
                image={""}
            />

            {/* Mobile */}
        {isMobile && (
            <div>
            <div className="flex md:flex-row md:-m-8" style={{marginTop: '5rem'}}>
                <div className="my-4" style={{marginRight: "3rem", marginLeft: "3rem"}}>
                    {firstParagraph()} 
                    </div>
            </div>
            <div
                className=" xl:ml-16 xl:mr-16 relative md-auto flex flex-col"
                style={{
                    alignItems: 'center',
                    gridTemplateRows: 'auto',
                    marginBottom: 0,
                    marginTop: "5rem"
                }}
            >
                 <img
                    style={{width: "23rem", paddingBottom: '2rem'}}
                    src={GroupPic}
                    alt="Group Picture"
                    />
                    <div style={{paddingRight: "2rem", marginLeft: "3rem"}}>
                        {secondParagraph()}
                        </div>
                   
            </div>
            <div
                className=" xl:ml-16 xl:mr-16 relative md-auto flex flex-col"
                style={{
                    gridTemplateRows: 'auto',
                    marginBottom: 0,
                    marginTop: "5rem",
                    alignItems: 'center',
                }}
            >
                <img
                    style={{width: "23rem", paddingBottom: '2rem'}}
                    src={OfficeImageA}
                    alt="Office Image A"
                    />
                    <div style={{paddingRight: "2rem", marginLeft: "3rem"}}>
                    {thirdParagraph()} 
                </div>
            </div>
            <div
                className=" xl:ml-16 xl:mr-16 relative md-auto flex flex-col"
                style={{
                    gridTemplateRows: 'auto',
                    marginBottom: 0,
                    marginTop: "5rem",
                    alignItems: 'center',
                }}
            >
                <img
                    style={{width: "23rem", paddingBottom: '2rem'}}
                    src={OfficeImageB}
                    alt="Office Image B"
                    />
                </div>
                <div style={{paddingRight: "2rem", marginLeft: "3rem"}}>
                {fourthParagraph()}
                </div>
                <div className="flex md:flex-row md:-m-8" style={{marginTop: '5rem'}}>
                <div className="my-4" style={{marginRight: "5rem", marginLeft: "5rem", paddingBottom: "10rem"}}>
                    <p className="leading-relaxed">
                    So, join us in welcoming the HEDGE team to our own at our Fitzrovia offices, and if you’re interested in knowing more or need to use our services, pop us a message.                   
                    </p>
                    
                </div>
            </div>
        </div>
        )}
            {/* Desktop */}
        {!isMobile && (
            <div>
                <div className="flex md:flex-row md:-m-8" style={{marginTop: '5rem'}}>
                    <div className="my-4" style={{marginRight: "13rem", marginLeft: "13rem"}}>
                    {firstParagraph()} 
                    </div>
                </div>
                <div
                    className="grid grid-cols-5 xl:ml-16 xl:mr-16 relative md-auto"
                    style={{
                        alignItems: 'center',
                        gridTemplateRows: 'auto',
                        marginBottom: 0,
                        marginTop: "5rem"
                    }}
                >
                        <div className='col-span-3' style={{paddingRight: "5rem", marginLeft: "7rem"}}>
                        {secondParagraph()}
                        </div>
                        <img
                        style={{width: "30rem"}}
                        className='col-span-2'
                        src={GroupPic}
                        alt="Group Picture"
                        />
                </div>
                <div
                    className="grid grid-cols-5 xl:ml-16 xl:mr-16 relative md-auto"
                    style={{
                        gridTemplateRows: 'auto',
                        marginBottom: 0,
                        marginTop: "5rem",
                        alignItems: 'center',
                    }}
                >
                    <img
                        className='col-span-2'
                        style={{width: "37rem"}}
                        src={OfficeImageA}
                        alt="Office Image A"
                        />
                        <div className='col-span-3' style={{paddingLeft: "5rem", marginRight: "7rem"}}>
                        {thirdParagraph()} 
                    </div>
                </div>
                <div
                    className="grid grid-cols-2 xl:ml-16 xl:mr-16 relative md-auto"
                    style={{
                        gridTemplateRows: 'auto',
                        marginBottom: 0,
                        marginTop: "5rem",
                        alignItems: 'center',
                    }}
                >
                    <div style={{paddingRight: "5rem", marginLeft: "7rem"}}>
                    {fourthParagraph()}
                    </div>
                    <img
                        style={{width: "39rem"}}
                        src={OfficeImageB}
                        alt="Office Image B"
                        />
                        
                </div>
                <div className="flex md:flex-row md:-m-8" style={{marginTop: '5rem'}}>
                <div className="my-4" style={{marginRight: "13rem", marginLeft: "13rem", paddingBottom: "10rem"}}>
                    <p className="leading-relaxed">
                    So, join us in welcoming the HEDGE team to our own at our Fitzrovia offices, and if you’re interested in knowing more or need to use our services, pop us a message.                   
                    </p>
                    
                </div>
            </div>
            </div>
            )}
            
        </Layout >
    )
}

export default AnnouncmentPage

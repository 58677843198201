import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import OfficeImage from '../../images/other/B0A8237.jpg'
import LogoVid from "../../images/announcement/Kontor_Hedge.mp4"

const FadingTitle = ({ title, image }) => {
    return (
        <Fragment>
            <div
                className="xl:ml-16 relative md-auto flex justify-center items-center flex-col"
                style={{
                    gridTemplateRows: 'auto',
                    marginBottom: 0,
                }}
            >

                <div className="text-left mt-24 mb-8 md:-mb-8 sm:mt-4 flex items-start flex-col justify-center mx-0 sm:col-end-3 md:mt-24">
                    <div className="max-w-full mx-auto md:ml-0">
                        <h1
                            // Opacity values entered here in a style prop
                            className=" transition-quick text-3xl md:text-4xl text-center sm:text-left mx-8 md:mx-0 sm:text-left"
                        >
                            {title}
                        </h1>
                    </div>

                </div>
                <video
                            style={{ width: "40%", borderRadius: '2rem', bottom: '0.5rem', paddingTop: "3rem" }}
                            autoPlay
                            loop
                            muted
                            playsinline
                        >
                            <source src={LogoVid} type="video/mp4" />
                        </video>
            </div>
        </Fragment>
    )
}

FadingTitle.propTypes = {
    boxText: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
}

export default FadingTitle
